/** @define products; weak */
.products {
  padding-top: 30px;
  padding-bottom: 60px;
  background-color: #ffffff;
}
.products__item {
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid #bebebe;
}
@media only screen and (max-width: 599px) {
  .products__item:first-child {
    border-top: 1px solid #bebebe;
  }
}
@media only screen and (min-width: 600px) and (max-width: 899px) {
  .products__item {
    width: 50%;
  }
  .products__item:nth-child(odd) {
    border-right: 1px solid #bebebe;
  }
  .products__item:nth-child(-n + 2) {
    border-top: 1px solid #bebebe;
  }
}
@media only screen and (min-width: 900px) and (max-width: 1099px) {
  .products__item {
    width: calc(100% / 3);
  }
  .products__item:last-child {
    margin: 0 auto;
    border-right: 1px solid #bebebe;
    border-left: 1px solid #bebebe;
  }
  .products__item:nth-child(-n + 3) {
    border-top: 1px solid #bebebe;
  }
  .products__item:nth-child(-n + 2) {
    border-right: 1px solid #bebebe;
  }
}
@media only screen and (min-width: 1100px) {
  .products__item {
    width: 25%;
    border-top: 1px solid #bebebe;
    border-right: 1px solid #bebebe;
  }
  .products__item:last-child {
    border-right: none;
  }
}
@media only screen and (min-width: 900px) and (max-width: 1099px) {
  .products__item--2line:last-child {
    margin: 0 0 0 auto;
    border-right: none;
  }
  .products__item--2line:nth-child(4n) {
    margin: 0 auto 0 0;
    border-right: 1px solid #bebebe;
  }
}
@media only screen and (min-width: 1100px) {
  .products__item--2line {
    border-top: 1px solid #bebebe;
    border-right: 1px solid #bebebe;
  }
  .products__item--2line:last-child {
    margin: 0 auto;
    border-top: none;
    border-right: 1px solid #bebebe;
    border-left: 1px solid #bebebe;
  }
}
.products__picture {
  height: 280px;
}
.products__name {
  padding: 20px 0;
}
.products__name h2 {
  font-size: 20px;
}
/*# sourceMappingURL=css/products.css.map */